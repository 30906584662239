export const TOGGLE_THEME: string = 'TOGGLE_THEME'

export const SET_INITIALIZATION: string = 'SET_INITIALIZATION'

export const LOGIN_SUCCESS: string = 'LOGIN_SUCCESS'
export const LOGIN_FAIL: string = 'LOGIN_FAIL'
export const LOGOUT: string = 'LOGOUT'
export const TOGGLE_LOADING: string = 'TOGGLE_LOADING'
export const PASSWORD_CHANGE_SUCCESS: string = 'PASSWORD_CHANGE_SUCCESS'
export const PASSWORD_CHANGE_FAIL: string = 'PASSWORD_CHANGE_FAIL'
export const REQUEST_PASSWORD_CHANGE_SUCCESS: string =
  'REQUEST_PASSWORD_CHANGE_SUCCESS'
export const REQUEST_PASSWORD_CHANGE_FAIL: string =
  'REQUEST_PASSWORD_CHANGE_FAIL'
export const REFRESH_TOKEN: string = 'REFRESH_TOKEN'
export const RECONNECT_ON_REFRESH: string = 'RECONNECT_ON_REFRESH'
export const EXPIRED_PASSWORD: string = 'EXPIRED_PASSWORD'

export const SET_ALERT: string = 'SET_ALERT'
export const REMOVE_ALERT: string = 'REMOVE_ALERT'

export const USER_CREATE_SUCCESS: string = 'USER_CREATE_SUCCESS'
export const USER_CREATE_FAIL: string = 'USER_CREATE_FAIL'
export const EMAIL_VERIFY_SUCCESS: string = 'EMAIL_VERIFY_SUCCESS'
export const EMAIL_VERIFY_FAIL: string = 'EMAIL_VERIFY_FAIL'
export const PHONE_VERIFY_SUCCESS: string = 'PHONE_VERIFY_SUCCESS'
export const PHONE_VERIFY_FAIL: string = 'PHONE_VERIFY_FAIL'
export const ONBOARD_LOADING: string = 'ONBOARD_LOADING'
export const REGISTER_SUCCESS: string = 'REGISTER_SUCCESS'
export const REGISTER_FAIL: string = 'REGISTER_FAIL'
export const SET_EMAIL: string = 'SET_EMAIL'
export const CLEAR_ONBOARDING: string = 'CLEAR_ONBOARDING'

export const USERINFO: string = 'USERINFO'
export const GETCOMPANIES: string = 'GETCOMPANIES'
export const CREATE_COMPANY: string = 'CREATE_COMPANY'
export const ADD_COMPANY: string = 'ADD_COMPANY'
export const COMPANY_DETAILS: string = 'COMPANY_DETAILS'
export const UPDATE_COMPANY: string = 'UPDATE_COMPANY'
export const TOGGLE_EDIT: string = 'TOGGLE_EDIT'
export const CLEAR_COMPANY_INFO: string = 'CLEAR_COMPANY_INFO'
export const TOGGLE_COMPANY_LOADING: string = 'TOGGLE_COMPANY_LOADING'

export const MONEYCORP_CONNECTED: string = 'MONEYCORP_CONNECTED'
export const MONEYCORP_DISCONNECTED: string = 'MONEYCORP_DISCONNECTED'
export const CLEAR_MONEYCORP: string = 'CLEAR_MONEYCORP'

export const GET_BENEFICIARIES: string = 'GET_BENEFICIARIES'
export const CREATE_BENEFICIARY_REQUEST: string = 'CREATE_BENEFICIARY_REQUEST'
export const CREATE_BENEFICIARY_SUCCESS: string = 'CREATE_BENEFICIARY_SUCCESS'
export const CREATE_BENEFICIARY_FAILURE: string = 'CREATE_BENEFICIARY_FAILURE'
export const GET_BENEFICIARY_TEMPLATES: string = 'GET_BENEFICIARY_TEMPLATES'
export const RESET_BENEFICIARY_TEMPLATES: string = 'RESET_BENEFICIARY_TEMPLATES'
export const FETCH_BENEFICIARY_DETAILS_REQUEST: string =
  'FETCH_BENEFICIARY_DETAILS_REQUEST'
export const FETCH_BENEFICIARY_DETAILS_SUCCESS: string =
  'FETCH_BENEFICIARY_DETAILS_SUCCESS'
export const FETCH_BENEFICIARY_DETAILS_FAILURE: string =
  'FETCH_BENEFICIARY_DETAILS_FAILURE'
export const CLEAR_BENEFICIARY_DETAILS: string = 'CLEAR_BENEFICIARY_DETAILS'
export const CLEAR_BENEFICIARIES: string = 'CLEAR_BENEFICIARIES'
export const TOGGLE_BENEFICIARY_LOADING: string = 'TOGGLE_BENEFICIARY_LOADING'
export const TOGGLE_BENEFICIARY_CREATING: string = 'TOGGLE_BENEFICIARY_CREATING'
export const SET_BENEFICIARY_ROWS: string = 'SET_BENEFICIARY_ROWS'
export const SET_BENEFICIARY_PAGE: string = 'SET_BENEFICIARY_PAGE'
export const GET_ALL_BENEFICIARIES: string = 'GET_ALL_BENEFICIARIES'
export const FETCH_BENEFICIARIES_BY_SYM_REQUEST: string =
  'FETCH_BENEFICIARIES_BY_SYM_REQUEST'
export const FETCH_BENEFICIARIES_BY_SYM_SUCCESS: string =
  'FETCH_BENEFICIARIES_BY_SYM_SUCCESS'
export const FETCH_BENEFICIARIES_BY_SYM_FAILURE: string =
  'FETCH_BENEFICIARIES_BY_SYM_FAILURE'
export const INIT_PAYMENT_BENEFICIARIES: string = 'INIT_PAYMENT_BENEFICIARIES'
export const GET_PAYMENT_CURRENCIES_REQUEST: string =
  'GET_PAYMENT_CURRENCIES_REQUEST'
export const GET_PAYMENT_CURRENCIES_SUCCESS: string =
  'GET_PAYMENT_CURRENCIES_SUCCESS'
export const GET_PAYMENT_CURRENCIES_FAILURE: string =
  'GET_PAYMENT_CURRENCIES_FAILURE'
export const SET_BENEFICIARY_SEARCH: string = 'SET_BENEFICIARY_SEARCH'
export const SET_BENEFICIARY_CURRENCY_FILTER: string =
  'SET_BENEFICIARY_CURRENCY_FILTER'
export const RESET_FORM: string = 'RESET_FORM'

export const ENQUEUE_SNACKBAR: string = 'ENQUEUE_SNACKBAR'
export const CLOSE_SNACKBAR: string = 'CLOSE_SNACKBAR'
export const REMOVE_SNACKBAR: string = 'REMOVE_SNACKBAR'

export const GET_CONVERSIONS: string = 'GET_CONVERSIONS'
export const GET_CONVERSIONS_ERROR: string = 'GET_CONVERSIONS_ERROR'
export const CREATE_CONVERSION: string = 'CREATE_CONVERSION'
export const GET_CONVERSION_DETAILS: string = 'GET_CONVERSION_DETAILS'
export const CLEAR_CONVERSION_DETAILS: string = 'CLEAR_CONVERSION_DETAILS'
export const CLEAR_CONVERSIONS: string = 'CLEAR_CONVERSIONS'
export const CONVERSION_REASONS: string = 'CONVERSION_REASONS'
export const GET_QUOTE: string = 'GET_QUOTE'
export const CLEAR_QUOTE: string = 'CLEAR_QUOTE'
export const GET_QUOTE_ERROR: string = 'GET_QUOTE_ERROR'
export const INVALIDATE_QUOTE: string = 'INVALIDATE_QUOTE'
export const CREATE_CONVERSION_ERROR: string = 'CREATE_CONVERSION_ERROR'
export const CREATE_CONVERSION_LOADING: string = 'CREATE_CONVERSION_LOADING'
export const SET_QUOTE_LOADING: string = 'SET_QUOTE_LOADING'
export const SET_CONVERSION_FILTER: string = 'SET_CONVERSION_FILTER'
export const CLEAR_CONVERSION_FILTER: string = 'CLEAR_CONVERSION_FILTER'
export const SET_ROWS_FILTER: string = 'SET_ROWS_FILTER'
export const SET_PAGES_FILTER: string = 'SET_PAGES_FILTER'
export const GET_NONE_CONVERSION_DTS: string = 'GET_NONE_CONVERSION_DTS'

export const GET_PAYMENTS: string = 'GET_PAYMENTS'
export const GET_PAYMENTS_ERROR: string = 'GET_PAYMENTS_ERROR'
export const GET_PAYMENT_DETAILS: string = 'GET_PAYMENT_DETAILS'
export const CREATE_TRANSFER: string = 'CREATE_TRANSFER'
export const CREATE_PAYMENT_REQUEST: string = 'CREATE_PAYMENT_REQUEST'
export const CREATE_PAYMENT_SUCCESS: string = 'CREATE_PAYMENT_SUCCESS'
export const CREATE_PAYMENT_FAILURE: string = 'CREATE_PAYMENT_FAILURE'
export const FUND_WALLET: string = 'FUND_WALLET'
export const SET_PAYMENT_FILTER: string = 'SET_PAYMENT_FILTER'
export const CLEAR_PAYMENT_FILTER: string = 'CLEAR_PAYMENT_FILTER'
export const SET_PAYMENTROWS_FILTER: string = 'SET_PAYMENTROWS_FILTER'
export const SET_PAYMENTPAGES_FILTER: string = 'SET_PAYMENTPAGES_FILTER'
export const SET_PAYMENT_LOADING: string = 'SET_PAYMENT_LOADING'
export const GET_NONE_PAYMENT_DATES: string = 'GET_NONE_PAYMENT_DATES'
export const CLEAR_PAYMENTS: string = 'CLEAR_PAYMENTS'
export const CLEAR_ITEMS: string = 'CLEAR_ITEMS'
export const SET_PAYMENT_PARAMS: string = 'SET_PAYMENT_PARAMS'
export const CLEAR_PAYMENT_DETAILS: string = 'CLEAR_PAYMENT_DETAILS'

export const GET_ACCOUNTS: string = 'GET_ACCOUNTS'
export const GET_BALANCES: string = 'GET_BALANCES'
export const GET_BALANCES_ERROR: string = 'GET_BALANCES_ERROR'
export const GET_BALANCE: string = 'GET_BALANCE'
export const CLEAR_BALANCES: string = 'CLEAR_BALANCES'
export const SET_BALANCES_LOADING: string = 'SET_BALANCES_LOADING'

export const GET_LOCKRATES: string = 'GET_LOCKRATES'
export const GET_LOCKRATES_ERROR: string = 'GET_LOCKRATES_ERROR'
export const CREATE_LOCKRATE_REQUEST: string = 'CREATE_LOCKRATE_REQUEST'
export const CREATE_LOCKRATE_SUCCESS: string = 'CREATE_LOCKRATE_SUCCESS'
export const GET_LOCKRATE_DETAILS: string = 'GET_LOCKRATE_DETAILS'
export const CLEAR_LOCKRATE_DETAILS: string = 'CLEAR_LOCKRATE_DETAILS'
export const CLEAR_LOCKRATES: string = 'CLEAR_LOCKRATES'
export const LOCKRATE_REASONS: string = 'LOCKRATE_REASONS'
export const GET_LOCKRATE_QUOTE: string = 'GET_LOCKRATE_QUOTE'
export const CLEAR_LOCKRATE_QUOTE: string = 'CLEAR_LOCKRATE_QUOTE'
export const GET_LOCKRATE_QUOTE_ERROR: string = 'GET_LOCKRATE_QUOTE_ERROR'
export const CREATE_LOCKRATE_FAILURE: string = 'CREATE_LOCKRATE_FAILURE'
export const CREATE_LOCKRATE_LOADING: string = 'CREATE_LOCKRATE_LOADING'
export const SET_LOCKRATE_FILTER: string = 'SET_LOCKRATE_FILTER'
export const CLEAR_LOCKRATE_FILTER: string = 'CLEAR_LOCKRATE_FILTER'
export const SET_LOCKRATE_ROWS_FILTER: string = 'SET_LOCKRATE_ROWS_FILTER'
export const SET_LOCKRATE_PAGES_FILTER: string = 'SET_LOCKRATE_PAGES_FILTER'
export const GET_NONE_CONVERSION_DATES: string = 'GET_NONE_CONVERSION_DATES'
export const SET_LOCKRATE_PARAMS: string = 'SET_LOCKRATE_PARAMS'
export const INVALIDATE_LOCKRATE_QUOTE: string = 'INVALIDATE_LOCKRATE_QUOTE'

export const GET_OVERVIEW: string = 'GET_OVERVIEW'
export const FETCH_OVERVIEW_REQUEST: string = 'FETCH_OVERVIEW_REQUEST'
export const GET_OVERVIEW_ERROR: string = 'GET_OVERVIEW_ERROR'
export const SET_OVERVIEW_LOADING: string = 'SET_OVERVIEW_LOADING'
export const CLEAR_OVERVIEW: string = 'CLEAR_OVERVIEW'

export const GET_AUTOMATIONS: string = 'GET_AUTOMATIONS'
export const GET_AUTOMATIONS_ERROR: string = 'GET_AUTOMATIONS_ERROR'
export const GET_AUTOMATION_DETAILS: string = 'GET_AUTOMATION_DETAILS'
export const CREATE_AUTOMATION_REQUEST: string = 'CREATE_AUTOMATION_REQUEST'
export const CREATE_AUTOMATION_SUCCESS: string = 'CREATE_AUTOMATION_SUCCESS'
export const CREATE_AUTOMATION_FAILURE: string = 'CREATE_AUTOMATION_FAILURE'
export const CREATE_AUTOMATION_LOADING: string = 'CREATE_AUTOMATION_LOADING'
export const GET_AUTOMATION_CURRENCIES: string = 'GET_AUTOMATION_CURRENCIES'
export const GET_AUTOMATION_QUOTE: string = 'GET_AUTOMATION_QUOTE'
export const CLEAR_AUTOMATION_QUOTE: string = 'CLEAR_AUTOMATION_QUOTE'
export const DELETE_AUTOMATION: string = 'DELETE_AUTOMATION'
export const UPDATE_AUTOMATION: string = 'UPDATE_AUTOMATION'
export const UPDATE_AUTOMATION_ERROR: string = 'UPDATE_AUTOMATION_ERROR'
export const CLEAR_AUTOMATIONS: string = 'CLEAR_AUTOMATIONS'
export const CLEAR_AUTOMATION_DETAILS: string = 'CLEAR_AUTOMATION_DETAILS'
export const SET_AUTOMATIONS_FILTER: string = 'SET_AUTOMATIONS_FILTER'
export const SET_AUTOMATIONS_ROWS_FILTER: string = 'SET_AUTOMATIONS_ROWS_FILTER'
export const SET_AUTOMATIONS_PAGES_FILTER: string =
  'SET_AUTOMATIONS_PAGES_FILTER'
export const GET_HISTORICAL_PRICES: string = 'GET_HISTORICAL_PRICES'
export const FETCH_AUTOMATION_BILLS_REQUEST: string =
  'FETCH_AUTOMATION_BILLS_REQUEST'
export const FETCH_AUTOMATION_BILLS_SUCCESS: string =
  'FETCH_AUTOMATION_BILLS_SUCCESS'
export const FETCH_AUTOMATION_BILLS_FAILURE: string =
  'FETCH_AUTOMATION_BILLS_FAILURE'

export const GET_QUICKBOOKSURL: string = 'GET_QUICKSBOOKSURL'
export const SET_QUICKBOOK_STATUS: string = 'SET_QUICKBOOK_STATUS'
export const CONNECT_QUICKBOOKS: string = 'CONNECT_QUICKBOOKS'
export const CONNECT_QUICKBOOKS_ERROR: string = 'CONNECT_QUICKBOOKS_ERROR'
export const CLEAR_QUICKBOOKS: string = 'CLEAR_QUICKBOOKS'
export const LOADING_QUICKBOOKS: string = 'LOADING_QUICKBOOKS'
export const DISCONNECT_QUICKBOOKS: string = 'DISCONNECT_QUICKBOOKS'
export const DISCONNECT_QUICKBOOKS_ERROR: string = 'DISCONNECT_QUICKBOOKS_ERROR'

export const GET_BILLS: string = 'GET_BILLS'
export const GET_BILLS_ERROR: string = 'GET_BILLS_ERROR'
export const GET_BILL_DETAILS: string = 'GET_BILL_DETAILS'
export const SET_BILL_LOADING: string = 'SET_BILL_LOADING'
export const CLEAR_BILLS: string = 'CLEAR_BILLS'
export const CLEAR_BILL_DETAILS: string = 'CLEAR_BILL_DETAILS'
export const GET_VENDOR_DETAILS: string = 'GET_VENDOR_DETAILS'
export const CLEAR_VENDOR_DETAILS: string = 'CLEAR_VENDOR_DETAILS'
export const GET_BILL_QUOTE: string = 'GET_BILL_QUOTE'
export const CLEAR_BILL_QUOTE: string = 'CLEAR_BILL_QUOTE'
export const SET_UPCOMING_BILLS: string = 'SET_UPCOMING_BILLS'
export const CLEAR_UPCOMING_BILLS: string = 'CLEAR_UPCOMING_BILLS'
export const CLEAR_HISTORICAL_PRICES: string = 'CLEAR_HISTORICAL_PRICES'

export const SET_BILL_FILTER: string = 'SET_BILL_FILTER'
export const CLEAR_BILL_FILTER: string = 'CLEAR_BILL_FILTER'
export const SET_BILL_ROWS_FILTER: string = 'SET_BILL_ROWS_FILTER'
export const SET_BILL_PAGES_FILTER: string = 'SET_BILL_PAGES_FILTER'

export const SET_BENEFICIARY_ROWS_FILTER: string = 'SET_BENEFICIARY_ROWS_FILTER'
export const SET_BENEFICIARY_PAGES_FILTER: string =
  'SET_BENEFICIARY_PAGES_FILTER'
export const SET_BENEFICIARY_FILTER: string = 'SET_BENEFICIARY_FILTER'

export const SET_SESSION_EXPIRATION: string = 'SET_SESSION_EXPIRATION'
export const SET_SESSION_IDLE: string = 'SET_SESSION_IDLE '
export const SET_SESSION_LAST_ACTIVITY_TIME: string =
  'SET_SESSION_LAST_ACTIVITY_TIME'

export const GET_REASONS: string = 'GET_REASONS'
export const GET_REASONS_CACHE: string = 'GET_REASONS_CACHE'
export const GET_CURRENCIES: string = 'GET_CURRENCIES'
export const GET_CURRENCIES_CACHE: string = 'GET_CURRENCIES_CACHE'
export const GET_CURRENCIES_PINGPONG: string = 'GET_CURRENCIES_PINGPONG'
export const GET_BEN_SUPPORTED_CURRENCIES_COUNTRIES_PINGPONG: string =
  'GET_BEN_SUPPORTED_CURRENCIES_COUNTRIES_PINGPONG'

export const SET_CURRENCY_PROVIDER: string = 'SET_CURRENCY_PROVIDER'
export const SET_PINGPONG_LOADING: string = 'SET_PINGPONG_LOADING'
export const CONNECT_PINGPONG: string = 'CONNECT_PINGPONG'
export const DISCONNECT_PINGPONG: string = 'DISCONNECT_PINGPONG'
export const CONNECT_PINGPONG_ERROR: string = 'CONNECT_PINGPONG_ERROR'
export const CLEAR_PINGPONG: string = 'CLEAR_PINGPONG'
export const SET_PINGPONG_TEMPLATE: string = 'SET_PINGPONG_TEMPLATE'
export const SET_PINGPONG_TEMPLATE_ERROR: string = 'SET_PINGPONG_TEMPLATE_ERROR'

export const GET_RECEIVING_ACCOUNTS: string = 'GET_RECEIVING_ACCOUNTS'
export const GET_RECEIVING_ACCOUNTS_ERROR: string =
  'GET_RECEIVING_ACCOUNTS_ERROR'
export const GET_RECEIVING_ACCOUNTS_TEMPLATES: string =
  'GET_RECEIVING_ACCOUNTS_TEMPLATES'
export const RECEIVING_ACCOUNTS_LOADING: string = 'RECEIVING_ACCOUNTS_LOADING'

export const CAMBRIDGE_CONNECTION_STATUS: string = 'CAMBRIDGE_CONNECTION_STATUS'
export const GET_CURRENCIES_CAMBRIDGE: string = 'GET_CURRENCIES_CAMBRIDGE'
export const FETCH_TEMPLATE_SUCCESS: string = 'FETCH_TEMPLATE_SUCCESS'
export const FETCH_TEMPLATE_REQUEST: string = 'FETCH_TEMPLATE_REQUEST'
export const FETCH_TEMPLATE_FAILURE: string = 'FETCH_TEMPLATE_FAILURE'
export const RESET_CAMBRIDGE_TEMPLATE: string = 'RESET_CAMBRIDGE_TEMPLATE'
export const RESET_CAMBRIDGE_PAYMENTS: string = 'RESET_CAMBRIDGE_PAYMENTS'
export const GET_CAMBRIDGE_WALLETS: string = 'GET_CAMBRIDGE_WALLETS'
export const GET_CAMBRIDGE_SETTLEMENTS: string = 'GET_SETTLEMENTS'
export const GET_CAMBRIDGE_PAYMENT_METHODS: string =
  'GET_CAMBRIDGE_PAYMENT_METHODS'
export const CLEAR_CAMBRIDGE: string = 'CLEAR_CAMBRIDGE'

export const CONNECT_OFX: string = 'CONNECT_OFX'
export const DISCONNECT_OFX: string = 'DISCONNECT_OFX'
export const DISCONNECT_CAMBRIDGE: string = 'DISCONNECT_CAMBRIDGE'
export const GET_OFX_CONVERSON_CURRENCIES: string =
  'GET_OFX_CONVERSON_CURRENCIES'
export const GET_OFX_PAYMENT_CURRENCIES: string = 'GET_OFX_PAYMENT_CURRENCIES'
export const GET_OFX_ACCOUNTS: string = 'GET_OFX_ACCOUNTS'
export const GET_OFX_ACCOUNTS_FAILURE: string = 'GET_OFX_ACCOUNTS_FAILURE'
export const GET_OFX_CLASSIFICATION: string = 'GET_OFX_CLASSIFICATION'
export const CLEAR_OFX: string = 'CLEAR_OFX'

//feature flags
export const FEATURE_OFX_TOGGLE: string = 'FEATURE_OFX_TOGGLE'
export const FEATURE_CAMBRIDGE_TOGGLE: string = 'FEATURE_CAMBRIDGE_TOGGLE'
export const FEATURE_PINGPONG_TOGGLE: string = 'FEATURE_PINGPONG_TOGGLE'
export const SET_OFX_TEMPLATE: string = 'SET_OFX_TEMPLATE'
export const RESET_OFX_TEMPLATE: string = 'RESET_OFX_TEMPLATE'

export const RECAPTCHA_FAIL: string = 'RECAPTCHA_FAIL'
export const RECAPTCHA_FAIL_REGISTER: string = 'RECAPTCHA_FAIL_REGISTER'

export const CLEAR_STORE: string = 'CLEAR_STORE'

export const UPDATE_VENDOR_REQUEST: string = 'UPDATE_VENDOR_REQUEST'
export const UPDATE_VENDOR_SUCCESS: string = 'UPDATE_VENDOR_SUCCESS'
export const UPDATE_VENDOR_FAILURE: string = 'UPDATE_VENDOR_FAILURE'
export const UPDATE_VENDOR_RESET: string = 'UPDATE_VENDOR_RESET'

export const GET_COMPANY_DETAILS_REQUEST: string = 'GET_COMPANY_DETAILS_REQUEST'
export const GET_COMPANY_DETAILS_SUCCESS: string = 'GET_COMPANY_DETAILS_SUCCESS'
export const GET_COMPANY_DETAILS_FAILURE: string = 'GET_COMPANY_DETAILS_FAILURE'

export const FETCH_PROFILE_REQUEST: string = 'FETCH_PROFILE_REQUEST'
export const FETCH_PROFILE_SUCCESS: string = 'FETCH_PROFILE_SUCCESS'
export const FETCH_PROFILE_FAILURE: string = 'FETCH_PROFILE_FAILURE'

export const UPDATE_PROFILE_REQUEST: string = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS: string = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAILURE: string = 'UPDATE_PROFILE_FAILURE'

export const DELETE_TABLE_ROW_REQUEST: string = 'DELETE_TABLE_ROW_REQUEST'
export const DELETE_TABLE_ROW_SUCCESS: string = 'DELETE_TABLE_ROW_SUCCESS'
export const DELETE_TABLE_ROW_FAILURE: string = 'DELETE_TABLE_ROW_FAILURE'

export const CHANGE_PASSWORD_REQUEST: string = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_SUCCESS: string = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE: string = 'CHANGE_PASSWORD_FAILURE'

export const REMOVE_TEMPLATE_ERROR: string = 'REMOVE_TEMPLATE_ERROR'

export const SET_USERS_DATA: string = 'SET_USERS_DATA'

export const SAVE_SIGNALR_MESSAGE: string = 'SAVE_SIGNALR_MESSAGE'
export const CLEAR_MESSAGES: string = 'CLEAR_MESSAGES'
export const DELETE_SIGNALR_MESSAGE: string = 'DELETE_SIGNALR_MESSAGE'
export const DELETE_SIGNALR_MESSAGES: string = 'DELETE_SIGNALR_MESSAGES'
export const CURRENT_SIGNALR_MESSAGES: string = 'CURRENT_SIGNALR_MESSAGES'
export const DELETE_CURRENT_SIGNALR_MESSAGE: string =
  'DELETE_CURRENT_SIGNALR_MESSAGE'
export const FETCH_MEMBERS_REQUEST: string = 'FETCH_MEMBERS_REQUEST'
export const FETCH_MEMBERS_SUCCESS: string = 'FETCH_MEMBERS_SUCCESS'
export const FETCH_MEMBERS_FAILURE: string = 'FETCH_MEMBERS_FAILURE'

export const UPDATE_MEMBER_REQUEST: string = 'UPDATE_MEMBER_REQUEST'
export const UPDATE_MEMBER_SUCCESS: string = 'UPDATE_MEMBER_SUCCESS'
export const UPDATE_MEMBER_FAILURE: string = 'UPDATE_MEMBER_FAILURE'

export const DELETE_MEMBER_REQUEST: string = 'DELETE_MEMBER_REQUEST'
export const DELETE_MEMBER_SUCCESS: string = 'DELETE_MEMBER_SUCCESS'
export const DELETE_MEMBER_FAILURE: string = 'DELETE_MEMBER_FAILURE'

export const SEND_INVITATION_REQUEST: string = 'SEND_INVITATION_REQUEST,'
export const SEND_INVITATION_SUCCESS: string = 'SEND_INVITATION_SUCCESS'
export const SEND_INVITATION_FAILURE: string = 'SEND_INVITATION_FAILURE'

export const CANCEL_INVITATION_REQUEST: string = 'CANCEL_INVITATION_REQUEST'
export const CANCEL_INVITATION_SUCCESS: string = 'CANCEL_INVITATION_SUCCESS'
export const CANCEL_INVITATION_FAILURE: string = 'CANCEL_INVITATION_FAILURE'

export const RESEND_INVITATION_REQUEST: string = 'RESEND_INVITATION_REQUEST'
export const RESEND_INVITATION_SUCCESS: string = 'RESEND_INVITATION_SUCCESS'
export const RESEND_INVITATION_FAILURE: string = 'RESEND_INVITATION_FAILURE'

export const CLEAR_INVITATION_STATE: string = 'CLEAR_INVITATION_STATE'
export const SKIP_OFX_REAUTHENTICATE: string = 'SKIP_OFX_REAUTHENTICATE'

export const INITIALTE_PHONE_VERIFY_REQUEST: string =
  'INITIALTE_PHONE_VERIFY_REQUEST'
export const INITIALTE_PHONE_VERIFY_SUCCESS: string =
  'INITIALTE_PHONE_VERIFY_SUCCESS'
export const INITIALTE_PHONE_VERIFY_FAILURE: string =
  'INITIALTE_PHONE_VERIFY_FAILURE'

export const GET_COREPAY_OPTIONS_REQUST: string = 'GET_COREPAY_OPTIONS_REQUST'
export const GET_COREPAY_OPTIONS_SUCCESS: string = 'GET_COREPAY_OPTIONS_SUCCESS'
export const GET_COREPAY_OPTIONS_FAILURE: string = 'GET_COREPAY_OPTIONS_FAILURE'

export const CREATE_CORPAY_ONBOARDING_APP_REQUEST: string =
  'CREATE_CORPAY_ONBOARDING_APP_REQUEST'
export const CREATE_CORPAY_ONBOARDING_APP_SUCCESS: string =
  'CREATE_CORPAY_ONBOARDING_APP_SUCCESS'
export const CREATE_CORPAY_ONBOARDING_APP_FAILURE: string =
  'CREATE_CORPAY_ONBOARDING_APP_FAILURE'

export const GET_COREPAY_REQUIRED_FILES_REQUST: string =
  'GET_COREPAY_REQUIRED_FILES_REQUST'
export const GET_COREPAY_REQUIRED_FILES_SUCCESS: string =
  'GET_COREPAY_REQUIRED_FILES_SUCCESS'
export const GET_COREPAY_REQUIRED_FILES_FAILURE: string =
  'GET_COREPAY_REQUIRED_FILES_FAILURE'

export const EDIT_UPLOADED_DOCUMENTS: string = 'EDIT_UPLOADED_DOCUMENTS'
export const GET_LOCKRATE_GUIDELINES_REQUEST: string =
  'GET_LOCKRATE_GUIDELINES_REQUEST'
export const GET_LOCKRATE_GUIDELINES_SUCCESS: string =
  'GET_LOCKRATE_GUIDELINES_SUCCESS'
export const GET_LOCKRATE_GUIDELINES_FAILURE: string =
  'GET_LOCKRATE_GUIDELINES_FAILURE'

export const SET_CORRELATION_ID: string = 'SET_CORRELATION_ID'
export const CONNECT_CAMBRIDGE_REQUEST: string = 'CONNECT_CAMBRIDGE_REQUEST'
export const CONNECT_CAMBRIDGE_SUCCESS: string = 'CONNECT_CAMBRIDGE_SUCCESS'
export const CONNECT_CAMBRIDGE_FAILURE: string = 'CONNECT_CAMBRIDGE_FAILURE'
