import axios from 'axios'
import { WORLDESK_URL, header_config } from '../api/config'
import { notify } from './notifications'
import { getCompanyDetails, setUserDataAction } from './company'
import {
  getPingPongCurrencies,
  getCambridgeCurrencies,
  getOfxConversionCurrecies,
  getOfxPaymentCurrencies,
  getOfxClassification,
  getPaymentCurrencies,
} from './references'
import { getOfxAccounts } from './ofxAccounts'
import {
  getCambridgeWallets,
  getCambridgeSettlements,
} from './cambridgeAccounts'
import {
  MONEYCORP_CONNECTED,
  SET_CURRENCY_PROVIDER,
  SET_PINGPONG_LOADING,
  CONNECT_PINGPONG,
  CONNECT_OFX,
  DISCONNECT_CAMBRIDGE,
  DISCONNECT_OFX,
  DISCONNECT_PINGPONG,
  CAMBRIDGE_CONNECTION_STATUS,
  MONEYCORP_DISCONNECTED,
  CONNECT_CAMBRIDGE_REQUEST,
  CONNECT_CAMBRIDGE_SUCCESS,
  CONNECT_CAMBRIDGE_FAILURE,
} from './types'
import { checkTokenExpiration } from './checkToken'
import isTokenValid from '../utils/isTokenValid'
import { updateActivityTime } from './session'
import { checkErrorStatus } from './error'
import { push } from '@lagunovsky/redux-react-router'

export const connectMoneyCorp =
  (company_id: string, login_id: string, api_key: string) =>
  async (dispatch: any) => {
    const body = JSON.stringify({ login_id, api_key })
    try {
      const res = await axios.post(
        WORLDESK_URL +
          `/management/api/moneycorp/account?companyid=${company_id}`,
        body,
        header_config
      )
      if (res.status === 200) {
        dispatch({ type: MONEYCORP_CONNECTED })
        dispatch(getCompanyDetails(company_id))
        dispatch(notify('Successfully connected to Money Corp', 'success'))
      }
      console.log(res)
    } catch (err: any) {
      dispatch(checkTokenExpiration(err))
      console.log(err)
      console.log(err.response)
      dispatch(notify('Failed to connect to Money Corp', 'error'))
      if (err && err.response && err.response.data && err.response.data.error) {
        dispatch(notify(err.response.data.error, 'error'))
      }
    }
  }

export const getPingPongURL = (company_id: string) => async (dispatch: any) => {
  dispatch(updateActivityTime())
  if (!isTokenValid()) return

  try {
    if (Boolean(Number(localStorage.getItem('reauthenticateNeeded')))) {
      localStorage.setItem('reauthenticateNeeded', '0')
    }

    const res = await axios.get(
      WORLDESK_URL +
        `/management/api/pingpong/authenticate?companyid=${encodeURIComponent(
          company_id
        )}`,
      header_config
    )
    window.open(res.data, '_self')
  } catch (err) {
    dispatch(notify('Failed to get Ping Pong URL', 'error'))
    dispatch(checkErrorStatus(err))
    dispatch(setLoading(false))
  }
}

export const connectPingPong =
  (company_id: string, code: string, state: string) =>
  async (dispatch: any, getState: any) => {
    const currentLocation = getState().router.location.pathname
    const body = JSON.stringify({ code, state })

    try {
      await axios.post(
        WORLDESK_URL +
          `/management/api/pingpong/connect?companyid=${encodeURIComponent(
            company_id
          )}`,
        body,
        header_config
      )
      dispatch({ type: CONNECT_PINGPONG })
      dispatch(getCompanyDetails(company_id))
      dispatch(notify('Connected to Ping Pong.', 'success'))

      if (currentLocation !== '/eula') {
        dispatch(push('/settings/integrations'))
      }
    } catch (err) {
      dispatch(checkErrorStatus(err))
      dispatch(notify('Failed to connect to Ping Pong.', 'error'))

      if (currentLocation !== '/eula') {
        dispatch(push('/settings/integrations'))
      }
    }
  }

export const connectCambridge =
  (company_id: string, client_code: string, client_key: string) =>
  async (dispatch: any) => {
    const body = JSON.stringify({ client_code, client_key })
    dispatch({ type: CONNECT_CAMBRIDGE_REQUEST })

    try {
      await axios.post(
        WORLDESK_URL +
          `/management/api/Cambridge/connect?companyid=${encodeURIComponent(
            company_id
          )}`,
        body,
        header_config
      )
      dispatch({ type: CONNECT_CAMBRIDGE_SUCCESS })

      dispatch(getCompanyDetails(company_id))
      dispatch(notify('Successfully connected to Corpay', 'success'))
    } catch (error) {
      dispatch({ type: CONNECT_CAMBRIDGE_FAILURE })
      console.log(error)
      dispatch(checkErrorStatus(error))
      dispatch(notify('Failed to connect to Corpay.', 'error'))
    }
  }

export const disconnectCambridgeAction =
  (company_id: string) => async (dispatch: any) => {
    try {
      await axios.delete(
        WORLDESK_URL +
          `/management/api/Cambridge?companyid=${encodeURIComponent(
            company_id
          )}`,
        { data: {} }
      )
      dispatch({ type: DISCONNECT_CAMBRIDGE })
      dispatch(updateProviderSelectedAction())
    } catch (error) {
      console.log(error)
      dispatch(checkErrorStatus(error))
      dispatch(notify('Failed to disconnect Corpay.', 'error'))
    }
  }

export const disconnectOfxAction =
  (company_id: string) => async (dispatch: any) => {
    try {
      await axios.delete(
        WORLDESK_URL +
          `/management/api/Ofx?companyid=${encodeURIComponent(company_id)}`,
        { data: {} }
      )
      dispatch({ type: DISCONNECT_OFX })
      dispatch(updateProviderSelectedAction())
    } catch (error) {
      console.log(error)
      dispatch(checkErrorStatus(error))
      dispatch(notify('Failed to disconnect Ofx.', 'error'))
    }
  }

export const updateProviderSelectedAction =
  () => async (dispatch: any, getState: any) => {
    const integrations = getState().company.company_details.integrations

    let provider = ''

    if (integrations.currency_providers.length) {
      provider = integrations.currency_providers[0].type
    }
    dispatch(setCurrencyProvider(provider))
  }

export const disconnectPingpongAction =
  (company_id: string) => async (dispatch: any) => {
    try {
      await axios.delete(
        WORLDESK_URL +
          `/management/api/PingPong?companyid=${encodeURIComponent(
            company_id
          )}`,
        { data: {} }
      )
      dispatch({ type: DISCONNECT_PINGPONG })
    } catch (error) {
      console.log(error)
      dispatch(checkErrorStatus(error))
      dispatch(notify('Failed to disconnect PingPong.', 'error'))
    }
  }

export const updateCambridge =
  (company_id: string, client_code: string, client_key: string) =>
  async (dispatch: any) => {
    const body = JSON.stringify({ client_code, client_key })

    try {
      dispatch({ type: CONNECT_CAMBRIDGE_REQUEST })

      let res = await axios.post(
        WORLDESK_URL +
          `/management/api/Cambridge/connect?companyid=${encodeURIComponent(
            company_id
          )}`,
        body,
        header_config
      )
      dispatch({ type: CONNECT_CAMBRIDGE_SUCCESS })

      dispatch(getCompanyDetails(company_id))

      if (res.data?.status === 'active')
        dispatch(notify('Successfully connected to Corpay', 'success'))
    } catch (error) {
      dispatch({ type: CONNECT_CAMBRIDGE_FAILURE })
      dispatch(notify('Failed to connect to Corpay.', 'error'))
    }
  }

export const getOfxURL =
  (company_id: string, force?: boolean) => async (dispatch: any) => {
    dispatch(updateActivityTime())
    if (!isTokenValid()) return

    const getForce = () => {
      if (force) {
        return '&force=true'
      }
      return ''
    }

    try {
      const res = await axios.get(
        WORLDESK_URL +
          `/management/api/ofx/authenticate?companyid=${encodeURIComponent(
            company_id
          )}${getForce()}`
      )
      if (Boolean(Number(localStorage.getItem('reauthenticateNeeded')))) {
        localStorage.setItem('reauthenticateNeeded', '0')
      }
      let url = res.data
      window.location.href = url
    } catch (err) {
      console.log(err)
      dispatch(notify('Failed to get OFX URL', 'error'))

      dispatch(checkErrorStatus(err))
      dispatch(setLoading(false))
    }
  }

export const connectOfx =
  (company_id: string, code: string, state: string) =>
  async (dispatch: any, getState: any) => {
    // const body = JSON.stringify({ code, state })
    const currentLocation = getState().router.location.pathname
    try {
      await axios.get(
        WORLDESK_URL +
          `/management/api/ofx/connect?companyid=${encodeURIComponent(
            company_id
          )}`,
        header_config
      )
      dispatch({ type: CONNECT_OFX })
      dispatch(notify('Connected to Ofx.', 'success'))
    } catch (err) {
      dispatch(checkErrorStatus(err))
      dispatch(notify('Failed to connect to Ofx.', 'error'))

      if (currentLocation !== '/eula') {
        dispatch(push('/settings/integrations'))
      }
    }
  }

export const quickbooksErrorMessageAction =
  () => async (dispatch: any, getState: any) => {
    const location = getState().router.location
    const query = new URLSearchParams(decodeURI(location.search))

    const getErrorMessage = (error: any) => {
      if (error === '1') {
        return 'Company is already connected with QuickBooks'
      } else if (error === '2') {
        return 'QuickBooks account is already connected with another company'
      } else if (error === '3') {
        return 'User refused to provide Worldesk access to his QuickBooks account'
      } else if (error === '4') {
        return 'Unknown error from QuickBooks'
      } else if (error === '5') {
        return 'Error getting token from QuickBooks'
      } else if (error === '0') {
        return 'Unknown Error'
      } else {
        return 'Unknown Error'
      }
    }

    if (query) {
      if (query.get('error') && query.get('error') === 'quickbooks') {
        if (query.get('id')) {
          dispatch(notify(getErrorMessage(query.get('id')), 'error'))
        }
      }
    }
  }

export const setCurrencyProvider =
  (provider: string) => async (dispatch: any, getState: any) => {
    const companyDetails = getState().company.company_details

    dispatch(
      setUserDataAction({
        company_id: companyDetails.id,
        providerSelected: provider === '' || provider ? provider : undefined,
      })
    )

    const currentLocation = getState().router.location.pathname
    const currecyProviders = companyDetails.integrations.currency_providers

    dispatch({ type: SET_CURRENCY_PROVIDER, payload: provider })
    dispatch(getPaymentCurrencies({ company_id: companyDetails.id }))

    if (currecyProviders.length) {
      // OFX
      let ofxIntegration = currecyProviders.find((p: any) => p.type === 'ofx')
      //ofxIntegration.state = 'partial';

      if (ofxIntegration) {
        if (ofxIntegration.state === 'active') {
          dispatch({ type: CONNECT_OFX })
          if (provider === 'ofx') {
            dispatch(getOfxPaymentCurrencies())
            dispatch(getOfxConversionCurrecies(companyDetails.id))
            dispatch(getOfxAccounts(companyDetails.id))
            dispatch(getOfxClassification(companyDetails.id))
          }
        } else {
          dispatch({ type: DISCONNECT_OFX })
          dispatch(notify('Please reconnect your OFX account.', 'info'))
          if (currentLocation !== '/eula') {
            dispatch(push('/settings/integrations'))
          }
        }
      }
    }
    // CAMBRIDGE
    let cambrigeIntegration = currecyProviders.find(
      (p: any) => p.type === 'cambridge'
    )
    if (cambrigeIntegration) {
      dispatch({
        type: CAMBRIDGE_CONNECTION_STATUS,
        payload: cambrigeIntegration.state,
      })
      if (cambrigeIntegration.state === 'active') {
        if (provider === 'cambridge') {
          dispatch(getCambridgeCurrencies())
          dispatch(getCambridgeWallets(companyDetails.id))
          dispatch(getCambridgeSettlements(companyDetails.id))
        }
      } else if (
        cambrigeIntegration.state === 'pending' ||
        cambrigeIntegration.state === 'partial'
      ) {
        dispatch(push('/settings/integrations'))
      }
    } else {
      dispatch({ type: DISCONNECT_OFX })
      dispatch({ type: CAMBRIDGE_CONNECTION_STATUS, payload: 'disconnect' })
      dispatch({ type: DISCONNECT_PINGPONG })
    }
  }

export const setLoading = (loading: boolean) => async (dispatch: any) => {
  dispatch({ type: SET_PINGPONG_LOADING, payload: loading })
}
