import axios from 'axios'
import {
  GET_CURRENCIES,
  // GET_REASONS,
  // GET_REASONS_CACHE,
  INIT_PAYMENT_BENEFICIARIES,
  GET_PAYMENT_CURRENCIES_REQUEST,
  GET_PAYMENT_CURRENCIES_SUCCESS,
  GET_PAYMENT_CURRENCIES_FAILURE,
  GET_CURRENCIES_PINGPONG,
  GET_BEN_SUPPORTED_CURRENCIES_COUNTRIES_PINGPONG,
  GET_CURRENCIES_CAMBRIDGE,
  GET_OFX_CONVERSON_CURRENCIES,
  GET_OFX_PAYMENT_CURRENCIES,
  GET_OFX_CLASSIFICATION,
} from './types'
import { WORLDESK_URL, header_config } from '../api/config'
import { notify } from './notifications'
import { checkErrorStatus } from './error'
// import { isEmptyObject, parseReasons } from 'utils/parseReasons'
import { parseSymbols } from '../utils/initBeneficiarySymbols'
// import moment from 'moment'

// export const getReasons = () => async (dispatch: any) => {
//   try {
//     let reasons_last_update = localStorage.getItem('reasons_last_update')
//     let reasons = JSON.parse(localStorage.getItem('reasons'))

//     if (
//       reasons_last_update &&
//       moment() < moment(reasons_last_update).add(60, 'minutes') &&
//       reasons &&
//       reasons.length > 0
//     ) {
//       dispatch({ type: GET_REASONS_CACHE, payload: reasons })
//     } else {
//       const res = await axios.get(
//         WORLDESK_URL + `/moneycorp/api/References/conversion/reasons`,
//         header_config
//       )

//       if (!isEmptyObject(res.data))
//         dispatch({ type: GET_REASONS, payload: parseReasons(res.data) })
//     }
//   } catch (err) {
//     console.log(err)
//     dispatch(notify('Failed to get List of Reasons', 'error'))
//     dispatch(checkErrorStatus(err))
//   }
// }

export const getCurrencies = () => async (dispatch: any) => {
  try {
    const res = await axios.get(
      WORLDESK_URL + `/bank/api/references/currencies`,
      header_config
    )
    dispatch({ type: GET_CURRENCIES, payload: res.data })
    dispatch({
      type: INIT_PAYMENT_BENEFICIARIES,
      payload: parseSymbols(res.data),
    })
  } catch (err) {
    console.log(err)
    dispatch(notify('Failed to get List of Currencies', 'error'))
    dispatch(checkErrorStatus(err))
  }
}

export const getPaymentCurrencies =
  ({ company_id }: { company_id: string }) =>
  async (dispatch: any, getState: any) => {
    try {
      const providerSelected =
        getState().currencyProvider.currentProviderSelected
      dispatch({ type: GET_PAYMENT_CURRENCIES_REQUEST })

      const res = await axios.get(
        WORLDESK_URL +
          `/${providerSelected}/api/references/currencies/payment?companyid=${company_id}`,
        header_config
      )

      dispatch({
        type: GET_PAYMENT_CURRENCIES_SUCCESS,
        payload: res.data,
      })
    } catch (err: any) {
      dispatch({ type: GET_PAYMENT_CURRENCIES_FAILURE })
    }
  }

export const getPingPongCurrencies = () => async (dispatch: any) => {
  try {
    const res = await axios.get(
      WORLDESK_URL + `/pingpong/api/References/currencies`,
      header_config
    )
    dispatch({ type: GET_CURRENCIES_PINGPONG, payload: res.data })
  } catch (err) {
    console.log(err)
    dispatch(notify('Failed to get List of PingPong Currencies', 'error'))
    dispatch(checkErrorStatus(err))
  }
}

export const getCambridgeCurrencies = () => async (dispatch: any) => {
  try {
    const res = await axios.get(
      WORLDESK_URL + `/cambridge/api/references/currencies`,
      header_config
    )
    dispatch({ type: GET_CURRENCIES_CAMBRIDGE, payload: res.data })
  } catch (err) {
    dispatch(notify('Failed to get List of Corpay Currencies', 'error'))
    dispatch(checkErrorStatus(err))
  }
}

export const getBenSupportCurrenciesPingPong = () => async (dispatch: any) => {
  try {
    const res = await axios.get(
      WORLDESK_URL + `/pingpong/api/References/beneficiaries/supported`,
      header_config
    )
    dispatch({
      type: GET_BEN_SUPPORTED_CURRENCIES_COUNTRIES_PINGPONG,
      payload: res.data,
    })
  } catch (err) {
    console.log(err)
    dispatch(
      notify('Failed to get List of Beneficiary Supported Currencies', 'error')
    )
    dispatch(checkErrorStatus(err))
  }
}

export const getOfxConversionCurrecies =
  (company_id: string) => async (dispatch: any) => {
    try {
      const res = await axios.get(
        WORLDESK_URL +
          `/ofx/api/references/currencies/conversions?companyid=${company_id}`,
        header_config
      )
      dispatch({ type: GET_OFX_CONVERSON_CURRENCIES, payload: res.data })
    } catch (err) {
      dispatch(
        notify('Failed to get List of Ofx Conversion Currencies', 'error')
      )
      dispatch(checkErrorStatus(err))
    }
  }

export const getOfxPaymentCurrencies = () => async (dispatch: any) => {
  try {
    const res = await axios.get(
      WORLDESK_URL + `/ofx/api/references/currencies/payments`,
      header_config
    )
    dispatch({ type: GET_OFX_PAYMENT_CURRENCIES, payload: res.data })
  } catch (err) {
    dispatch(notify('Failed to get List of Ofx Payment Currencies', 'error'))
    dispatch(checkErrorStatus(err))
  }
}

export const getOfxClassification =
  (company_id: string) => async (dispatch: any) => {
    try {
      const res = await axios.get(
        WORLDESK_URL +
          `/ofx/api/References/beneficiaries/classification?companyId=${company_id}`,
        header_config
      )
      dispatch({ type: GET_OFX_CLASSIFICATION, payload: res.data })
    } catch (err) {
      dispatch(notify('Failed to get Ofx Classification', 'error'))
      dispatch(checkErrorStatus(err))
    }
  }
